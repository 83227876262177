




















import { Component, Vue } from 'vue-property-decorator'

// utils
import { emojiList } from '@/utils/constants'

@Component
export default class EmojiPicker extends Vue {
  private scrollOptions = {
    scrollPanel: {
      scrollingX: false,
    },
    vuescroll: {
      sizeStrategy: 'number',
    },
  }

  private emojiList: string[] = emojiList
}
