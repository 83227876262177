

























import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

import EmojiPicker from '@/components/EmojiPicker.vue'
import DetectSafariMixin from '@/mixins/DetectSafariMixin'

@Component({
  components: {
    EmojiPicker,
  },
  inheritAttrs: false,
})
export default class TextAreaInput extends DetectSafariMixin {
  @Ref() textarea!: Vue

  @Prop({ default: '' })
  private value!: string

  @Prop({ default: '' })
  private label!: string

  @Prop({ default: true })
  private outlined!: boolean

  @Prop({ default: 2 })
  private rows!: number

  @Prop({ default: false })
  private oneLine!: boolean

  @Prop({ default: false })
  private invalid!: boolean

  @Prop({ default: '' })
  private error!: string

  @Prop({ default: true })
  private autoGrow!: boolean

  @Prop({ default: false })
  private emojis!: boolean

  private innerValue = ''
  private textareaEl: HTMLTextAreaElement | null = null
  private cursorPositionEnd = 0
  private cursorPositionStart = 0

  private mounted () {
    this.innerValue = this.value ?? ''
    if (this.textarea) {
      this.textareaEl = this.textarea.$el.querySelector('textarea') ?? null
    }
  }

  private handleInput (value: string) {
    this.innerValue = value
    this.$emit('input', this.innerValue)
  }

  private handleEnter () {
    this.$emit('enter', this.innerValue)
  }

  private handleOpenEmojiPicker() {
    if (this.textareaEl) {
      this.cursorPositionEnd = this.textareaEl.selectionEnd
      this.cursorPositionStart = this.textareaEl.selectionStart
    }
  }

  private handleInsertEmoji(emoji: string) {
    const textBefore = this.innerValue.substring(0, this.cursorPositionStart)
    const textAfter = this.innerValue.substring(this.cursorPositionEnd, this.innerValue.length)
    this.innerValue = `${textBefore}${emoji}${textAfter}`
    this.$emit('input', this.innerValue)
    setTimeout(() => {
      if (this.textareaEl) {
        this.textareaEl.setSelectionRange(this.cursorPositionStart + 1, this.cursorPositionStart + 1)
        this.textareaEl.focus()
      }
    }, 0)
  }

  @Watch('value')
  private watchValue (value: string) {
    this.innerValue = value
  }
}
